var maxScrollY;
var zoom = (function ($) {
    var selectors = {
            document: document,
            window: window,
            body: 'body, html',
            scene: '.scene',
            layers: '.layer',
            depth: '.scene__depth',
            menu: '.menu',
            anchor: 'a[href^="#"]'
        },
        classes = {
            activeMenu: 'menu__item--active'
        },
        distance = 500,
        speed = 500,
        current = {
            layer: 0,
            progress: 0,
            menu: ''
        },
        depth,
        layers,
        nodes;

    function zoomScene() {
        // get scroll, cap within bounds
        var scroll = nodes.window.scrollTop();
        scroll = scroll >= 0 ? (scroll <= depth ? scroll : depth) : 0;

        // set currents
        current.layer = (scroll / distance) | 0;
        $('.layer').not(':eq('+current.layer+')').removeClass('active');
        $('.layer').eq(current.layer).addClass('active');
        current.progress = (scroll - (current.layer * distance)) / distance;
        current.overallProgress = (scroll / (distance * layers));

        // adjust scene
        
        setZPosition(nodes.scene, scroll);

        // update menu and layer
        setActive();
    }

    function setActive() {
        // get scroll, cap within bounds
        var scroll = nodes.window.scrollTop();
        scroll = scroll >= 0 ? (scroll <= depth ? scroll : depth) : 0;

        // update menu
        var position = (scroll / distance) | 0;
        // var position = current.layer + Math.round(current.progress);
        // console.log('position', position);

        if (position !== current.menu) {
            var layer = $('.layer[data-depth="' + position * distance + '"]');

            nodes.menu.find('.' + classes.activeMenu).removeClass(classes.activeMenu);

            nodes.menu.find('a[href="#' + layer.attr('id') + '"]').addClass(classes.activeMenu);

            current.menu = position;
        }
    }

    function setZPosition(element, z) {
        element.css({
            '-webkit-transform': 'translate3d(0, 0px, ' + z + 'px)',
            '-moz-transform': 'translate3d(0, 0, ' + z + 'px)',
            'transform': 'translate3d(0, 0, ' + z + 'px)',
        });

    }

    function scrollToLayer(target) {
        nodes.body.stop(true).animate({
            'scrollTop': target
        }, speed, 'easeInExpo');
    }

    function setDepth() {
        layers = nodes.layers.length;
        depth = (distance * (layers - 1)) + nodes.window.height();

        nodes.depth.css('height', depth + 'px');
    }

    return {
        init: function () {
            nodes = utils.createNodes(selectors);
            // set environment depth
            setDepth();

            // set layer z position
            $.each(nodes.layers, function () {
                var layer = $(this);

                setZPosition(layer, -layer.data('depth'));
            });

            // set initial position
            zoomScene();

            // zooming
            var throttledZoom = _.throttle(zoomScene, 25);

            nodes.window.on('scroll', throttledZoom);

            // resize
            nodes.window.on('resize', setDepth);

            // anchors
            nodes.anchor.on('click', function (event) {
                var target = $($(this).attr('href')).data('depth');

                scrollToLayer(target);

                event.preventDefault();
            });

            console.log('nodes.length', nodes);
            maxScrollY = nodes.layers[nodes.layers.length - 1].dataset.depth;
        }
    }

    

})(jQuery);


$(function () {
    $( window ).scroll(function() {
        // saving position if is a video for exit fullscreen
        if ($('.layer.active').find('.video').length > 0) {
            currentVideoOffsetY = $('.layer.active')[0].getAttribute('data-depth');
        }
        // limit scroll down (because of footer)
        if ( window.pageYOffset > maxScrollY ) {
        window.scrollTo(maxScrollY, maxScrollY);
        }
    });

    // Scroll on fullscreen exit
    $(window).bind('webkitfullscreenchange mozfullscreenchange fullscreenchange', function(e) {
        var state = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        if(!state) {
            window.scrollTo(0, currentVideoOffsetY);
        };
    });

});
